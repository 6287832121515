/* stylelint-disable no-descending-specificity */
@layer utilities {
  .container {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    margin: 0;
    /* display: flex; */
    margin-left: 30%;
  }

  .main-container {
    display: flex;
    align-items: stretch;
    height: 795px;
    width: 1248px;
  }

  .left-container-items .label {
    font-size: 14px;
    width: 100%;
    color: black;
    padding-right: 40px; /* Adjust to make space for the icon */
  }

  .left-container-items input {
    border: 1px solid #aeaeae;
    padding: 12px 15px;
    font-size: 14px;
    width: 100%;
    border-radius: 3px;
    color: black;
    padding-right: 40px; /* Adjust to make space for the icon */
  }

  .left-container,
  .right-container {
    flex: 1;
    padding: 20px;
  }

  .left-container {
    background-color: #ffffff;
  }

  .right-container {
    background-color: #ffffff;
  }

  .btn-disabled-priorauth-submit {
    background: theme('colors.gray.DEFAULT');
    color: theme('colors.gray.medium');
    cursor: not-allowed;
  }

  .btn-enabled-priorauth-submit {
    background: theme('colors.gold.DEFAULT');
    color: theme('colors.black');
    cursor: pointer;
    font-weight: bold;
  }

  input {
    border-color: theme('colors.gray.DEFAULT');
    border-width: 1px;
  }

  .tooltip {
    max-width: 300px;
    z-index: 9999;
  }
}
