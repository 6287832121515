/* stylelint-disable no-descending-specificity */
@layer utilities {

  .prosanohealth-header-element {
    font-weight: bold;
    margin-top: -121px;
    padding-top: 120px;
    margin-left: -27%;
    margin-right: -27%;
    background-color: theme('colors.blue.porcelain');
    height: 272px;
    background-position: left;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
  }

  .prosanohealth-header-element:before {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 60px;
    left: calc(50% - 30px);
  }

  .prosanohealth-header-element:after {
    position: absolute;
    align-self: auto;
    top: 0;
    bottom: 0%;
    right: 0;
    transform-style: preserve-3d;
    transform: rotateX(0)rotateY(180deg)rotateZ(180deg);
  }

  .prosanohealth-header-element + div>div.rte {
    position: relative;
    top: -85px;
  }

  /* Mobile */
  @media screen and (max-width: 768px) {
    .prosanohealth-header-element:after {
      display: none !important;
    }

    .prosanohealth-header-element:before {
      display: none !important;
    }

    .prosanohealth-header-element {
      /* margin-top: -121px !important;
      padding-top: 60px !important;
      margin-left: -20px !important;
      margin-right: -20px !important;
      background-color: theme('colors.blue.porcelain') !important;
      height: 152px !important; */
      font-weight: bold !important;
      margin-top: -121px !important;
      padding-top: 120px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      background-color: theme('colors.blue.porcelain') !important;
      height: 310px !important;
      background-position: left;
      background-repeat: no-repeat;
      overflow: hidden !important;
      position: relative;
    }

    .prosanohealth-header-element + div>div.rte {
      position: relative;
      top: -95px;
    }
  
  }
}