/* If you see some red underline errors likes for colors, these are coming from website specific themes */

@layer utilities {
  .theme-white {
    --theme-bg: theme('colors.white');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.gray.darkest');
    --theme-text-alt: theme('colors.gray.dark');
    --theme-hr-bg: theme('colors.gray.darkest');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-link-text: theme('colors.blue.DEFAULT');
    --btn-link-text-hover: theme('colors.blue.navy');
    --theme-heading-text: theme('colors.gray.darkest');
  }

  .theme-prosano-white {
    --theme-bg: theme('colors.white');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.gray.darkest');
    --theme-text-alt: theme('colors.gray.dark');
    --theme-hr-bg: theme('colors.gray.darkest');
    --theme-heading-text: theme('colors.gray.darker');

    /* Primary */
    --btn-bg-primary: theme('colors.blue.peacock');
    --btn-bg-primary-hover: theme('colors.blue.peacockDark');
    --btn-border-primary-hover: theme('colors.blue.peacock');
    --btn-border-primary: theme('colors.blue.peacock');
    --btn-text-primary: theme('colors.white');

    /* Secondary */
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-bg-secondary: theme('colors.white');
    --btn-text-secondary: theme('colors.gray.darker');
    --btn-text-secondary-hover: theme('colors.gray.darker');

    --btn-link-text: theme('colors.blue.DEFAULT');
    --btn-link-text-hover: theme('colors.blue.navy');

    /* tertiary */
    --btn-bg-tertiary: theme('colors.blue.peacock');
    --btn-text-tertiary: theme('colors.gray.darker');
    --btn-svg-text-tertiary: theme('colors.white');
  }

  .theme-blue {
    --theme-bg: theme('colors.blue.DEFAULT');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.white');
    --theme-text-alt: theme('colors.gray.darkest');
    --theme-hr-bg: theme('colors.white');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-link-text: theme('colors.white');
    --btn-link-text-hover: theme('colors.gray.light');
    --btn-text-tertiary: theme('colors.white');
    --theme-heading-text: theme('colors.white');
  }
  .theme-prosano-blue {
    --theme-bg: theme('colors.blue.DEFAULT');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.white');
    --theme-text-alt: theme('colors.gray.darkest');
    --theme-hr-bg: theme('colors.white');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-link-text: theme('colors.white');
    --btn-link-text-hover: theme('colors.gray.light');
    --theme-heading-text: theme('colors.white');

    /* Primary */
    --btn-bg-primary: theme('colors.blue.peacock');
    --btn-bg-primary-hover: theme('colors.blue.peacockDark');
    --btn-border-primary-hover: theme('colors.blue.peacock');
    --btn-border-primary: theme('colors.blue.peacock');
    --btn-text-primary: theme('colors.white');

    /* Secondary */
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-text-secondary: theme('colors.gray.darker');
    --btn-text-secondary-hover: theme('colors.gray.darker');

    /* tertiary */
    --btn-bg-tertiary: theme('colors.blue.peacock');
    --btn-text-tertiary: theme('colors.white');
    --btn-svg-text-tertiary: theme('colors.white');
  }
  .theme-blue--light {
    --theme-bg: theme('colors.blue.light');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.gray.darkest');
    --theme-text-alt: theme('colors.white');
    --theme-hr-bg: theme('colors.gray.darkest');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --theme-heading-text: theme('colors.gray.darkest');
  }

  .theme-prosano-blue--light {
    --theme-bg: theme('colors.blue.light');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.gray.darkest');
    --theme-text-alt: theme('colors.white');
    --theme-hr-bg: theme('colors.gray.darkest');

    /* Primary */
    --btn-bg-primary: theme('colors.blue.peacock');
    --btn-bg-primary-hover: theme('colors.blue.peacockDark');
    --btn-border-primary-hover: theme('colors.blue.peacock');
    --btn-border-primary: theme('colors.blue.peacock');
    --btn-text-primary: theme('colors.white');
    --theme-heading-text: theme('colors.gray.darker');

    /* Secondary */
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-text-secondary: theme('colors.gray.darker');
    --btn-text-secondary-hover: theme('colors.gray.darker');

    /* tertiary */
    --btn-bg-tertiary: theme('colors.blue.peacock');
    --btn-text-tertiary: theme('colors.gray.darker');
    --btn-svg-text-tertiary: theme('colors.white');
  }

  .theme-blue--peacock {
    --theme-bg: theme('colors.blue.peacock');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.white');
    --theme-text-alt: theme('colors.gray.darkest');
    --theme-hr-bg: theme('colors.white');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --theme-heading-text: theme('colors.gray.darkest');
  }

  /* See if we actually need this */
  .theme-split--blue-white {
    --theme-bg: theme('colors.blue.light');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.gray.darkest');
    --theme-text-alt: theme('colors.white');
    --theme-hr-bg: theme('colors.gray.darkest');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');

    background: linear-gradient(180deg, theme('colors.blue.light') 33%, theme('colors.white') 33%);
  }

  .theme-blue--navy {
    --theme-bg: theme('colors.blue.navy');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.white');
    --theme-text-alt: theme('colors.gray.light');
    --theme-hr-bg: theme('colors.white');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-link-text: theme('colors.white');
    --btn-link-text-hover: theme('colors.gray.light');
    --btn-text-tertiary: theme('colors.white');
    --theme-heading-text: theme('colors.gray.light');
  }
  .theme-prosano-blue--navy {
    /* theme */
    --theme-bg: theme('colors.gray.darker');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.white');
    --theme-text-alt: theme('colors.gray.light');
    --theme-hr-bg: theme('colors.white');
    --theme-heading-text: theme('colors.white');

    /* Primary theme */
    --btn-bg-primary: theme('colors.blue.peacock');
    --btn-bg-primary-hover: theme('colors.blue.peacockDark');
    --btn-border-primary-hover: theme('colors.blue.peacockDark');
    --btn-border-primary: theme('colors.blue.peacock');
    --btn-text-primary: theme('colors.white');
    /* primary DISABLED */
    /* All of these configured in the tailwind.base.config.js */

    /* Secondary button  */
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --btn-text-secondary: theme('colors.gray.darker');
    --btn-text-secondary-hover: theme('colors.gray.darker');
    /* Secondary DISABLED */
    /* All of these configured in the tailwind.base.config.js */

    /* tertiary */
    --btn-bg-tertiary: theme('colors.blue.peacock');
    --btn-text-tertiary: theme('colors.white');

    --btn-link-text: theme('colors.white');
    --btn-link-text-hover: theme('colors.gray.light');
    --btn-svg-text-tertiary: theme('colors.white');
  }

  .theme-gray {
    --theme-bg: theme('colors.gray.light');
    --theme-bg-alt: rgb(255 255 255 / 5%);
    --theme-text: theme('colors.gray.darkest');
    --theme-text-alt: theme('colors.black');
    --theme-hr-bg: theme('colors.gray.darkest');
    --btn-bg-secondary: theme('colors.white');
    --btn-bg-secondary-hover: theme('colors.white');
    --theme-heading-text: theme('colors.gray.darkest');
  }

  .alert-warning {
    --theme-bg: theme('colors.warning.DEFAULT');
    --theme-text: theme('colors.warning.color');
  }

  .alert-info {
    --theme-bg: theme('colors.info.DEFAULT');
    --theme-text: theme('colors.info.color');
  }

  .heading-theme-black{
    color: theme('colors.black') !important;
  }

  .heading-theme-white{
    color: theme('colors.white') !important;
  }

  .heading-theme-blue{
    color: theme('colors.blue.DEFAULT') !important;
  }


  @screen md {
    .narrow-container [data-component='authorable/layout/layout-page-section'] {
      @apply relative after:absolute after:w-full after:h-full after:bg-theme-bg after:left-full after:top-0 before:absolute before:w-full before:h-full before:bg-theme-bg before:right-full before:top-0;
    }
  }
}
